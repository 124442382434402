<template>
    <v-dialog fullscreen persistent v-model="fileEditorDialog">
        <template v-if="isCreateReadmeFile" v-slot:activator="{ on }">
            <v-btn color="secondary" v-on="on" text>
                <v-icon left>add</v-icon>
                add readme file
            </v-btn>
        </template>
        <template v-else-if="editable" v-slot:activator="{ on }">
            <v-btn class="justify-start" :color="btnColor" v-on="on" :text="!loading" :block="isBlock" :disabled="disabled" :loading="loading">
                <v-icon left small>edit</v-icon>
                edit
            </v-btn>
        </template>
        <template v-else v-slot:activator="{ on }">
            <v-btn class="justify-start" :color="btnColor" v-on="on" :text="!loading" :block="isBlock" :disabled="disabled" :loading="loading">
                <v-icon left small>mdi-eye</v-icon>
                view
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex justify-space-between align-center title secondary--text w-100">
                    <div v-if="isCreateReadmeFile" class="d-flex align-center text-uppercase">
                        <v-icon class="mr-1">add</v-icon>
                        <span v-if="isCreateReadmeFile">Add Readme file</span>
                    </div>
                    <div class="d-flex align-center" v-else>
                        <v-icon class="mr-1">edit</v-icon>
                        <div class="d-flex title flex-column">
                            <span class="font-weight-medium">Edit File</span>
                            <span class="secondary--text subtitle-2">{{ [fileData.local_path, fileData.short_id].join('/') }}</span>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <template v-if="editable">
                            <v-btn text :disabled="fileLoading" @click="discardChanges">discard</v-btn>
                            <v-btn class="ml-1" :loading="fileLoading" color="primary" text @click="updateFileContent()">save</v-btn>
                        </template>
                        <v-btn v-else text :disabled="fileLoading" color="primary" @click="fileEditorDialog = false">close</v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-0 pb-0">
                <MarkdownEditor
                    editorHeight="calc(100vh - 83px)"
                    @newMarkdownContent="fileContent = $event.value"
                    :editorText="fileContent"
                    :editorMode="originalFileContent.includes('<iframe') ? 'markdown' : 'wysiwyg'"
                    :fetchingFile="fetchingFile"
                    v-if="isMarkdownFile && fileEditorDialog" />
                <MonacoEditor
                    v-else
                    class="monaco-editor"
                    :style="{ height: 'calc(100vh - 82px)', overflow: 'hidden' }"
                    :language="language"
                    v-model="fileContent"
                    :options="{
                        minimap: { enabled: false },
                        fontSize: 14,
                        renderLineHighlight: false,
                        automaticLayout: true,
                        autoIndent: true,
                        lineNumbersMinChars: 5,
                        scrollbar: { vertical: 'visible' },
                        scrollBeyondLastLine: false,
                        smoothScrolling: true,
                        readOnly: !editable
                    }" />
                <div>
                    <v-dialog persistent v-model="changeWarningDialog" max-width="500">
                        <v-card>
                            <v-card-title>
                                <span class="title secondary--text">Discard changes</span>
                            </v-card-title>
                            <v-divider class="mb-1"></v-divider>

                            <v-card-text>
                                <p>You have unsaved changes.</p>
                                <p>Are you sure you want to discard your changes?</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="fileEditorDialog = false">discard</v-btn>
                                <v-btn color="secondary" text @click="changeWarningDialog = false">review changes</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
const MonacoEditor = () => import('vue-monaco')
const MarkdownEditor = () => import('@/components/MarkdownEditor')

export default {
    name: 'FileEditor',
    props: {
        fileData: Object,
        endpoint: String,
        language: String,
        isCreateReadmeFile: { default: false, type: Boolean },
        newReadmeFileContent: String,
        isBlock: { default: false, type: Boolean },
        isMarkdownFile: Boolean,
        disabled: { default: false, type: Boolean },
        loading: { default: false, type: Boolean },
        editable: { default: true, type: Boolean }
    },
    components: {
        MonacoEditor,
        MarkdownEditor
    },
    data() {
        return {
            fileContent: '',
            originalFileContent: '',
            fileEditorDialog: false,
            fileLoading: false,
            changeWarningDialog: false,
            addedEventListener: false,
            fetchingFile: false
        }
    },
    computed: {
        btnColor() {
            return this.loading ? '' : 'secondary'
        }
    },
    methods: {
        discardChanges() {
            if (this.originalFileContent === this.fileContent) {
                window.removeEventListener('beforeunload', this.warnUnload)
                this.fileEditorDialog = false
            } else {
                this.changeWarningDialog = true
            }
        },
        warnUnload(event) {
            // Cancel the event as stated by the standard.
            event.preventDefault()
            // Older browsers supported custom message
            event.returnValue = ''
        },
        readFileContent() {
            this.fetchingFile = true
            this.$axios
                .get(`/files/${this.$props.fileData.fid}/download`, {
                    timeout: 10000,
                    responseType: 'blob'
                })
                .then(response => {
                    const reader = new FileReader()
                    reader.addEventListener('loadend', e => {
                        const text = e.target.result
                        this.originalFileContent = text
                        this.fileContent = text
                        this.fetchingFile = false
                    })
                    reader.readAsText(response.data)
                })
                .catch(e => {
                    console.log(e)
                    this.fetchingFile = false
                })
        },
        updateFileContent() {
            this.fileLoading = true
            const blob = new Blob([this.fileContent])
            const formData = new FormData()
            if (this.isCreateReadmeFile) {
                formData.append('file', blob, 'README.md')
                formData.append('type', 'text/markdown')
                formData.append('name', 'README.md')
            } else {
                formData.append('file', blob, this.$props.fileData.short_id)
                formData.append('type', this.$props.fileData.type)
                formData.append('name', this.$props.fileData.short_id)
            }

            this.$axios
                .post(this.$props.endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    if (this.isCreateReadmeFile) {
                        this.$emit('readmeFileCreated')
                    } else {
                        this.$emit('readmeFileUpdated')
                    }
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to the edit the file.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .finally(() => {
                    window.removeEventListener('beforeunload', this.warnUnload)
                    this.fileLoading = false
                    this.fileEditorDialog = false
                })
        }
    },
    mounted() {
        if (this.disabled) return
        if (!this.isCreateReadmeFile) {
            this.readFileContent()
        } else {
            this.fileContent = this.$props.newReadmeFileContent
            this.originalFileContent = this.$props.newReadmeFileContent
        }
    },
    watch: {
        fileEditorDialog: function (nextVal) {
            if (nextVal && !this.isCreateReadmeFile) {
                this.readFileContent()
            }
        },
        fileContent: {
            handler: function (to, from) {
                if (to !== this.originalFileContent && !this.addedEventListener) {
                    this.addedEventListener = true
                    window.addEventListener('beforeunload', this.warnUnload)
                } else {
                    window.removeEventListener('beforeunload', this.warnUnload)
                }
            },
            immediate: true
        }
    }
}
</script>
